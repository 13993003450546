import "./App.css";
import AppNavigator from "./AppNavigator";
function App() {
  return (
    <>
      <AppNavigator />
    </>
  );
}

export default App;
